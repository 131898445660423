






















































import { Component, Emit, Model, Vue, Watch } from "vue-property-decorator";
import Rules from "@/app/modules/_global/classes/Rules";
import { mask } from "vue-the-mask";

import CountryCodes from "@/assets/country_codes.json";
import DefaultCountryCodes from "@/assets/default_country_codes.json";

@Component({
  directives: {
    mask
  }
})
export default class Phone extends Vue {
  @Model("input", { type: String }) readonly data!: string;

  @Emit()
  input() {
    return `+${this.countryCode} ${this.localData}`;
  }

  @Watch("localData")
  onLocalDataChanged() {
    this.input();
  }

  @Watch("countryCode")
  onCountryCodeChanged() {
    this.input();
  }

  countryCode!: number;
  countryCodes!: object;
  defaultCountryCodes!: object;

  localData: string;

  rules = {
    required: Rules.required
  };

  constructor() {
    super();

    this.countryCodes = CountryCodes;
    this.defaultCountryCodes = DefaultCountryCodes;

    const phone = this.data?.match(/\+(\d+) (\d+)/);

    this.countryCode = Number(phone?.[1] ?? 41);
    this.localData = String(phone?.[2] ?? "");
  }

  getFlag(iso: string) {
    return require(`@/assets/flags/${iso}.svg`);
  }

  setDefaultCountryCode(code: number) {
    this.countryCode = code;
    (this.$refs.countryCode as HTMLElement).blur();
  }
}
